* {
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}
.content {
  width: calc(100% - 50px);
  margin-left: auto;
  height: 100%;
  word-break: break-word;
}
.content--right {
  width: calc(100% - 220px);
  transition: width 0.3s ease;
}
.content--left {
  width: calc(100% - 50px);
  transition: width 0.3s ease;
}
.input-group .form-control:not(:last-child) {
  border-right: 1px solid lightgrey;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 5 !important;
  border-bottom-right-radius: 0;
}

.btn {
  box-shadow: none;
}

.btn:hover {
  box-shadow: none;
}
