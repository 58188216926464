
* {
     box-sizing: border-box;
}

.search-form {
     font-size: small;
     margin: 30px auto 0px auto;
     border: 1px solid rgb(177, 176, 176);
     border-radius: 10px;
     padding: 0%;
}

.search-form input[type="text"] {
     border-radius: 10px;
     border: none;
     font-size: small;
     height: auto;
     margin: 0%;
}

.search-form select {
     border: none;
     padding: 8px;
}

.search-form input[type="button"]{
     background-color: #2F455c;
     color: white;
     font-size: small;
     height: fit-content;
     border: none;
     box-shadow: none;
     border-top-right-radius: 10px;
     border-bottom-right-radius: 10px;
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;     
     margin: 0%;
}
.custom-row-class{
     color: red;
}
.btn-sm {
     padding: 2px 15px;
     font-size: smaller;
}

.btn-theme-green {
     background-color: aquamarine;
     color: #2F455c;
}
.btn:hover{
     -webkit-transform: none;
     transform:none;
}
@media (max-width: 575.98px) {
.search-form input[type="button"] {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 10px;
     }
}