.sidebar {
    position: fixed;
    top: 0;
    width: 50px;
    height: 100%;
    background-color: white;
    /* transition: width 0.3s ease; */
    padding-top: 15px;
}
.sidebar .sidebar-position {
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 12px;
    text-align: left;
    font-size: 14px;
    border-left: 4px white solid;
}
.sidebar .sidebar-position:hover {
    background-color: #eeeeee;
    border-left: 4px #eeeeee solid;    
}
.sidebar .active {
    border-left: 4px #8AD8BC solid;
    color: #8AD8BC;
    font-weight: 700;   
}
.sidebar svg {
    color: #8AD8BC;
    min-width: 25px;
}
.sidebar span {
    width: 0;
    height: 0;
    padding: 0 15px;
    /* transition: width 0.3s ease; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebar--open {
    width: 220px;
    transition: width 0.3s ease;
}
.sidebar--open .sidebar-position span {
    display: block;
    width: 100%;
    height: auto;
}
.trigger {
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px 15px;
}